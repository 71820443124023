@import url("https://use.typekit.net/fvk4nfp.css");

$primaryfont: 'lato', sans-serif;
$secondaryfont: 'futura-pt', sans-serif;

$color1: #20ADB4;
$color1opacity30: #4389C930;
$color1opacity60: #4389C960;
$color2: #343333;
$color3: #343333;
$color4: #E74B16;
$color5: #20ADB4;
$color6: #20ADB4;
$color7: #272424;

$gradient1: linear-gradient(90deg, #D3202B 0%, #EE7E2D 87.34%);

body { background: #8FCBD3; font-family: "lato", sans-serif; color: #1f2a44; }

header {
  & { background: #fff; height: 105px; width: 100%; margin: 0 auto; display: flex; align-items: center; justify-content: center; position: relative; }
  .logo { position: absolute; left: 0; background: #E74B16; padding: 20px 30px 30px 30px; border-radius: 0px 0px 20px 20px; margin-left: -5px; }
  .logo img { width: 95px; }
  .assessment-title { flex: 1 1 auto; text-align: center; color: #E74B16; text-transform: uppercase; font-family: $secondaryfont; font-weight: 700; font-size: 24px; }
}

.background-wrapper { background: rgba(255,255,255,0.5); }
.not-on-mobile { display: none; }
.adjust45 { height: 45px; }

main {
  .container { margin: 80px auto 0; max-width: 1110px; border-radius: 10px; }
  .loading { display: flex; justify-content: center; align-items: center; }
  
  .start { 
    & { height: 580px; background: #fff; display: flex; margin-bottom: 50px; }
    .video { 
      & { border-top-left-radius: 10px; border-bottom-left-radius: 10px; position: relative; width: 50%; background: #333; height: 100%; display: flex; justify-content: center; /*align-items: center; object-fit: cover;*/ overflow: hidden; }
      video { margin: 0 0 0 95px; }
      .thumbnail { position: absolute; height: 100%; overflow: hidden; }
      .playbtn { position: absolute; top: 50%; margin-top: -70px; left: 50%; margin-left: -70px; cursor: pointer; }
    }
    .intro {
      & { width: 36%; padding: 0 7%; display: flex; align-items: left; flex-direction: column; justify-content: center; }
      h1 { margin: 0; margin-bottom: 28px; font-family: $primaryfont; font-size: 48px; color: $color2; }
      h2 { margin: 0; margin-bottom: 0; font-weight: 300; font-style: normal; color: #343333; font-size: 24px; line-height: 35px; }
      .completion { 
        & { display: flex; align-items: center; margin-top: 48px; color: #343333; font-weight: 700; font-size: 18px; }
        .cta { display: block; background: $color4; border-radius: 5px; color: #fff; text-decoration: none; width: 240px; text-align: center; font-family: $primaryfont; font-size: 21px; font-weight: 600; text-transform: uppercase; padding: 10px 0 14px; margin-left: 0; }
        .cta:hover { background: $color6; color: #fff; }
        img { width: 25px; height: 25px; margin: 0 4px 0 15px; }
      }
    }
  }

  .assessment {
    & { min-height: 580px; background: #fff; width: 95%; margin-bottom: 50px; }
    .steps {
      & { margin: 0 auto 50px; width: 80%; min-height: 480px; }
      .spacer { height: 50px; }

      .progress-bar {
        & { display: flex; justify-content: space-between; width: 100%; }
        .bar-section { 
          .bar { height: 5px; margin: 0 5px 10px; }
          .section-title { width: 100%; text-align: center; font-family: $primaryfont; font-weight: 400; color: $color5; font-size: 21px; }
        }

        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 6px; border-radius: 3px; }
        .custom-bar .MuiLinearProgress-bar { background: $color5; }
      }

      .progress-indicator { margin: 70px auto 50px; width: 100%; text-align: center; font-family: $primaryfont; font-weight: 400; color: $color4; font-size: 21px; }

      .question { min-height: 150px; text-align: center; font-family: $primaryfont; font-weight: 400; color: $color3; font-size: 24px; }

      .slider-container {
        & { width: 86%; margin: 0 auto; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
          .point {
            & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #9b9b9b; position: absolute; z-index: 9; }
          }
        }
        .slider-labels {
          & { width: 125%; font-weight: 400; font-size: 21px; color: #343333; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; font-family: $primaryfont; }
          }
          .active { font-weight: 800; color: #20ADB4; }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: $color5; }

        .custom-slider .MuiSlider-rail { opacity: 0; }
        .custom-slider .MuiSlider-track { border: 5px solid $color5; }

        .custom-slider .MuiSlider-thumb { background: $color5; }
        .custom-slider .MuiSlider-thumb:before { background: #fff; border: 4px solid $color5; width: 14px; height: 14px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background: $color1; }

        // .css-eglki6-MuiLinearProgress-root {
        //   background: #d8d8d8;
        // }
        // .css-5xe99f-MuiLinearProgress-bar1 {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb:before {
        //   background: #fff;
        //   border: 4px solid $color1;
        //   width: 14px;
        //   height: 14px;
        // }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
        //   box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5);
        // }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      }

      .slider-none-adjust { height: 35px; }
      .step-buttons {
        & { display: flex; justify-content: space-between; margin: 90px auto 30px; }
        button { color: #343333; font-weight: 600; font-size: 14px; }
      }
    }
  }

  .assessment-additional {
    & { min-height: 580px; background: #fff; border-radius: 10px; color: #fff; width: 95%; margin-bottom: 50px; }
    .steps {
      & { margin: 0 auto 50px; width: 80%; min-height: 480px; }
      .spacer { height: 50px; }
      h1 { color: $color3; font-size: 21px; text-align: center; }

      .progress-indicator { margin: 70px auto 50px; width: 100%; text-align: center; font-family: $primaryfont, sans-serif; font-weight: 400; color: $color4; font-size: 21px; }

      .question { min-height: 150px; text-align: center; font-family: $primaryfont, sans-serif; font-weight: 400; color: $color3; font-size: 24px; }
      .textarea { min-height: 80px; }
      .textarea-field { max-width: 540px; width: 80%; margin: 0 auto; margin-bottom: -37px; }
      .MuiInput-root { font-family: $primaryfont, sans-serif !important; font-size: 21px; }
      .form-details-text { background-color: #fff; padding: 12px 9px; border: 1px solid #9b9b9b; border-radius: 5px; }

      .slider-container {
        & { width: 86%; margin: 0 auto; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
          .point {
            & { cursor: pointer; width: 13px; height: 13px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #9b9b9b; position: absolute; z-index: 9; }
          }
        }
        .slider-labels {
          & { width: 125%; font-weight: 400; font-size: 21px; color: $color3; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
          .active { font-weight: 800; color: #20ADB4; }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: $color5; }

        .custom-slider .MuiSlider-rail { opacity: 0; }
        .custom-slider .MuiSlider-track { border: 5px solid $color5; }

        .custom-slider .MuiSlider-thumb { background: $color5; }
        .custom-slider .MuiSlider-thumb:before { background: #fff; border: 4px solid $color5; width: 14px; height: 14px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; }

        // .css-eglki6-MuiLinearProgress-root {
        //   background: #d8d8d8;
        // }
        // .css-5xe99f-MuiLinearProgress-bar1 {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb:before {
        //   background: #fff;
        //   border: 4px solid $color1;
        //   width: 14px;
        //   height: 14px;
        // }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
        //   box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5);
        // }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      }

      .slider-none-adjust { height: 34px; }
      .step-buttons {
        & { display: flex; justify-content: space-between; margin: 80px auto 30px; padding-bottom: 60px; }
        button { color: $color3; font-weight: 600; font-size: 14px; }
      }

      .cta-btn {
        & { display: block; margin: 10px -19px 8px 0; text-decoration: none; }
        a { text-decoration: none; }
        button { width: 212px; background: $color4; font-family: $primaryfont; font-weight: 700; font-size: 21px; color: #fff; border-radius: 5px; padding: 8px 0; text-align: center; text-transform: uppercase; }
        button:hover { background: $color6; color: #fff; }
        .button-progress { margin: 4px 0 -2px; }
      }

    }
  }

  .details {
    & { min-height: 630px; width: 95%; background: #fff; }
    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: $primaryfont, sans-serif; font-size: 36px; color: #1f2a44; padding: 32px 0 0; }
    p { text-align: center; font-size: 24px; font-weight: 400; font-family: $primaryfont; color: $color3; }

    .form {
      & { display: flex; justify-content: space-between; flex-wrap: wrap; margin: 38px auto; width: 60%; }
      .form-detail { 
        & { position: relative; flex: 0 1 46%; display: block; margin-bottom: 30px; font-weight: 700; /* overflow: hidden; */ }
        p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 700; font-family: $primaryfont; }
        .MuiInput-root { font-family: $primaryfont !important; font-size: 21px !important; font-weight: 600; border-radius: 3px; }
        .MuiInput-root input::placeholder { font-weight: 300; }
        .MuiSelect-select .first-menu { color: #fff; }
        .form-details-text { background-color: #f4f4f4; padding: 12px 15px; max-width: 90%; border-radius: 3px; font-size: 18px; }
        .form-details-text::placeholder { font-size: 18px !important; color: #9B9B9B; }
        .error-text { position: absolute; bottom: -20px; color: red; font-weight: 400; font-size: 13px; }
        input:focus::placeholder { color: transparent; }
      }
    }

    .cta-btn {
      & { margin: 20px auto 60px; text-decoration: none; }
      a { text-decoration: none; }
      button { width: 336px; background: $color4; font-family: $primaryfont; font-weight: 700; font-size: 21px; color: #fff; border-radius: 5px; padding: 8px 0; text-align: center; text-transform: uppercase; }
      button:hover { background: $color6; color: #fff; }
      .button-progress { margin: 4px 0 -2px; }
    }
  }

  .results {
    & { min-height: 660px; background: #fff; }
    
    .selection-form { 
      &  { width: 60%; display: flex; align-items: center; margin: 20px auto; }
      .form-detail { flex: 0 1 68%; margin-right: 4%;}
      .form-text { flex: 0 1 28%; }
      .form-details-text { background-color: #fff; border: 1px solid #efefef; padding: 12px 15px; max-width: 90%; }
    }

    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: $primaryfont, serif; font-size: 36px; color: #333333; padding: 32px 0 0; }
    h1 span { color: $color4; }

    .progress-bar { margin: 80px auto 40px; }
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: #d3202b; font-size: 18px; font-weight: 900; font-family: "Avenir", Sans-serif; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: "Aleo", Sans-serif; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 552px; height: 26px; margin: 0 auto; border-radius: 6px; width: 100%; background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%); }
    .si_ass_progress .progress-line { width: 2px; height: 55px; content: ' '; background: #4a4a4a; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: bold; color: #333333; bottom: 60px; left: 0; margin-left: -20px; }
    
    .pervalue h4 { position: relative; }
    .pervalue { max-width: 552px; margin: -16px auto; display: flex; clear: both; color: #333333; font-size: 14px; }
    .pervalue h4.nimp:before { content: '0%'; position: absolute; left: 0; }
    .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; }
    .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; }
    .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: 0; }

    .si_pro_header { max-width:552px; margin: 10px auto 0; display: flex; clear: both; }
    .si_pro_header h4 { position: relative; font-size: 16px;  font-weight: 400; text-align: center; color: #333333; text-transform: capitalize; margin-top: 10px; }
    .si_pro_header h4.nimp:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; bottom: 62px; }
    .si_pro_header h4.mexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: 66px; position: absolute; right: 0; }
    .si_pro_header h4 span { color: #D3202B; line-height: 35px; font-size: 16px; }
    
    .result-text { text-align: center; font-size: 21px; color: #121212; margin: 20px 15%; padding: 15px 45px; background: #F2F2F2; text-align: left; border-radius: 10px; }
    .result-sections { 
      & { margin: 50px 18%; display: flex; justify-content: center; flex-wrap: wrap; }
      .score-section {
        & { flex: 0 1 40%; text-align: center; color: #333333; font-weight: 400; font-size: 21px; margin: 10px 5%; }
        .score-txt { font-size: 18px; margin-bottom: 14px; font-weight: 700; font-family: $primaryfont; }
        .score-slider { width: 80%; margin: 0 auto; margin-bottom: 8px; }
        .bold { color: $color4; font-weight: 700; }
      }
    }

    .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 13px; }
    .custom-bar .MuiLinearProgress-bar { background: $color4; }
    //.css-eglki6-MuiLinearProgress-root { background: #E9E9E9; height: 5px; }
    //.css-5xe99f-MuiLinearProgress-bar1 { background: $color1; height: 5px; }

    .members-answered {
      & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "lato", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }
      .section {
        & { background-color: $color1; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; display: flex; align-items: center; justify-content: space-between; }
        .section-toggle { display: block; cursor: pointer; height: 24px; }
      }
      .answered-detail { 
        & { font-weight: 300; font-size: 18px; color: #4A4A4A; padding: 10px 30px; width: calc(100% - 60px); display: flex; align-items: center; }        
        .answer { flex: 1 1 285px; margin-left: 10px; text-align: right; font-weight: 700; }
        p { margin: 0; }
        .true { color: #73B76F; }
        .neutral { color: #FFB545; }
        .false { color: #FF6645; }
      }
      .answered-detail:nth-child(even) { background-color: #f4f4f4; }      
    }

  }

  .invite {
    & { min-height: 380px; width: 100%; max-width: 1110px; padding-bottom: 30px; background: #fff; margin-bottom: 50px; }
    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: $primaryfont, sans-serif; font-size: 36px; color: $color3; padding: 67px 0 0; }
    p { text-align: center; margin: 25px auto 35px; font-size: 21px; font-weight: 400; }
    .form {
      & { position: relative; display: flex; justify-content: space-between; flex-wrap: wrap; margin: 10px auto; width: 45%; }
      .invite-number { position: absolute; left: -50px; top: 67px; font-weight: bold; color: $color3; }
      .form-detail {
        & { flex: 0 1 46%; display: block; margin-bottom: 5px; font-weight: 700; }
        p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 700; }
        .MuiInput-root { font-family: $primaryfont, sans-serif !important; font-size: 21px; font-weight: 600; border-radius: 3px; }
        .MuiInput-root input::placeholder { font-weight: 300; }
        .invite-field { background-color: #F4F4F4; padding: 8px 9px; border-radius: 3px; font-weight: 600; }
        .invite-field::placeholder { font-size: 18px !important; color: #9B9B9B; font-weight: 300; }        
        .error-text { position: absolute; bottom: -20px; color: red; font-weight: 400; font-size: 13px; }
        input:focus::placeholder { color: transparent; }
      }      
      .delete-btn { 
        & { position: absolute; right: -57px; top: 67px; }
        .delete-icon { color: #D5D5D5; font-size: 24px; cursor: pointer; }
      }
    }

    .invite-another-person {
      & { margin: 0 auto; width: 55%; position: relative; height: 5px; margin-top: 20px; }
      .invite-another { width: 67px; background: $color1; position: absolute; top: -75px; cursor: pointer; right: -86px; border-radius: 5px; text-align: center; font-size: 18px; padding: 9px 0; color: #fff; }
    }

    .cta-btn {
      & { display: block; margin: 30px auto 50px; text-decoration: none; width: 180px; background: $color4; color: #fff; font-family: $primaryfont, serif; border-radius: 5px; font-size: 21px; padding: 8px 0; text-align: center; text-transform: uppercase; }
      &:hover { background: $color6; transition: 0.3s; }
      .button-progress { margin: 4px 0 -2px; }
    }
  }

  .thank-you {
    & { min-height: 380px; max-width: 1110px; width: 100%; padding-bottom: 30px; background: #fff; }
    .tick { text-align: center; padding: 80px 0 0; }
    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: $primaryfont, serif; font-size: 36px; color: $color3; padding: 32px 0 15px; }
    p { width: 65%; margin: 0 auto; text-align: center; font-size: 21px; color: $color3; }
    .back-btn { 
      & { text-align: center; margin-top: 50px; }
      a { border-bottom: 2px solid $color4; text-transform: uppercase; font-weight: bold; cursor: pointer; text-decoration: none; color: #202020; }
    }
  }

  .invite-container {
    & { width: 90%; max-width: calc(900px - 10%); margin: 20px auto 50px; padding: 35px 5% 30px; font-family: $primaryfont; font-size: 24px; font-weight: 400; line-height: 36px; color: $color7; text-align: center; }
    .height20 { height: 20px; }
    .cta-btn {
      & { width: 280px; background: $color4; margin: 40px auto 0; color: #fff; padding: 2px 0; border-radius: 5px; }
      &:hover { background: $color6; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { color: #fff; font-family: $primaryfont; font-size: 21px; font-weight: 700; }
    }
  }

  .individual-group {
    & { max-width: 400px; width: 90%; margin: 0 auto 40px; display: flex; }
    button { cursor: pointer; flex: 0 1 50%; border: 0px; background: #fff; color: #1F2A44; font-family: "helvetica", sans-serif; font-size: 18px; font-weight: 600; padding: 16px 0; text-align: center; }
    .left { border-top-left-radius: 100px; border-bottom-left-radius: 100px; }
    .right { border-top-right-radius: 100px; border-bottom-right-radius: 100px; }
    .active { background: $color1; color: #fff; }
  }

  .dashboard {
    & { min-height: 630px; background: #FBF9F2; }
    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: 'Butler', serif; font-size: 36px; color: #333333; padding: 32px 0 0; }
    
    .progress-bar { margin: 80px auto 40px; }
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: #d3202b; font-size: 18px; font-weight: 900; font-family: "Avenir", Sans-serif; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: "Aleo", Sans-serif; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 552px; height: 26px; margin: 0 auto; border-radius: 6px; width: 100%; background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%); }
    .si_ass_progress .progress-line { width: 2px; height: 55px; content: ' '; background: #4a4a4a; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: bold; color: #333333; bottom: 60px; left: 0; margin-left: -20px; }
    
    .pervalue h4 { position: relative; }
    .pervalue { max-width: 552px; margin: -16px auto; display: flex; clear: both; color: #333333; font-size: 14px; }
    .pervalue h4.nimp:before { content: '0%'; position: absolute; left: 0; }
    .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; }
    .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; }
    .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: 0; }

    .si_pro_header { max-width:552px; margin: 10px auto 0; display: flex; clear: both; }
    .si_pro_header h4 { position: relative; font-size: 16px;  font-weight: 400; text-align: center; color: #333333; text-transform: capitalize; margin-top: 10px; }
    .si_pro_header h4.nimp:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; bottom: 62px; }
    .si_pro_header h4.mexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: 66px; position: absolute; right: 0; }
    .si_pro_header h4 span { color: #D3202B; line-height: 35px; font-size: 16px; }
    
    .result-text { text-align: center; font-size: 21px; color: #121212; margin: 20px 20%; }
    .result-sections { 
      & { margin: 50px 5%; display: flex; justify-content: center; flex-wrap: wrap; }
      .score-section { 
        & { flex: 0 1 25%; text-align: center; color: #333333; font-weight: 600; font-size: 16px; margin: 10px auto; }
        .score-txt { font-size: 14px; margin-bottom: 4px; }
        .score-slider { width: 80%; margin: 0 auto; }
      }
    }

    .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
    .custom-bar .MuiLinearProgress-bar { background-color: $color1; }

    table { 
      & { font-family: 'helvetica', sans-serif; border-collapse: collapse; width: 86%; margin: 70px auto 40px; font-size: 18px;font-weight: 300; }
      tr:nth-child(even) { background-color: #F4F4F4; }
      td, th { border: 1px #dddddd;text-align: left;padding: 8px; }
      .center-cell { text-align: center; }
      .medium { font-weight: 500; }
      .hash { width: 13px; }
      .header { border-style: none;border-bottom-style: solid;border-width: 3px;border-color: $color1; }
      .footer { 
        & { border-style: none;border-top-style: solid;border-width: 3px;border-color: $color1; color: $color1; }
        .church-average { font-size: 14px; color: #373737; }
      }
    }

    hr { width: 96%; color: #979797; }
 
    h2 { font-family: "butler", serif; font-size: 36px; color: #4A4A4A; margin-top: 50px; text-align: center; }
    .most-answered {
      & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "helvetica", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }
      .green { background-color: #73B76F; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; }
      .red { background-color: #FF6645; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; }
      .most-answered-detail { font-weight: 300; font-size: 18px; color: #4A4A4A; padding: 10px 30px; width: calc(100% - 60px);}
      .most-answered-detail:nth-child(even) { background-color: #f4f4f4; }
    }

    .alignment { 
      & { max-width: calc(860px - 10%); margin: 0 auto 30px; background: #1f2a44; margin: 50px auto 0; padding: 10px; border-radius: 3px; }
      .container-tabs { display: flex; flex-direction: column; position: relative; width: 100%; min-height: 400px; }
      .tab-buttons { 
        & {display: flex; width: 100%; margin: 0 auto; }
        button { font-family: "helvetica", sans-serif; color: #fff; font-weight: 600; text-transform: uppercase; text-align: center; padding: 15px; background: none; border: 0px; min-width: 110px; cursor: pointer; }
        .active { background: $color1; color: #294469; }
      }
      .tabs-content { flex-grow: 1; }

      .content { 
        & { width: 100%; display: none; }
        .align-details {
          & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "helvetica", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }
          .green { background-color: #73B76F; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; }
          .red { background-color: #FF6645; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; }
          .answered-detail { 
            & { display: flex; flex-wrap: wrap; align-items: center; font-weight: 300; font-size: 14px; color: #4A4A4A; padding: 10px 15px; width: calc(100% - 30px); }
            .answer { flex: 1 1 200px; margin-left: 10px; text-align: right; font-weight: 700; }
            .true { color: #73B76F; }
            .neutral { color: #FFB545; }
            .false { color: #FF6645; }
            p { flex: 0 1 94%; }
            .more-toggle { display: block; flex: 0 1 6%; cursor: pointer; }
            .more-details { 
              & { display: none; width: 91%; margin-left: 5%; padding: 5px 2%; border-left: 3px solid #E66767; font-size: 12px; }
              .more-row { 
                & { display: flex; align-items: center; }
                p { max-width: 30%; padding: 5px; margin: 0; }
                .more-answer { text-transform: uppercase; font-weight: bold; }
              }
            }
          }
          .no-wrap { flex-wrap: nowrap; }
          .answered-detail:nth-child(even) { background-color: #f4f4f4; }
        }
        
      }
      .active-content { display: block; }
    }

    .urgency {
      & { max-width: calc(880px - 10%); margin: 20px auto; }
      h2 { font-family: "helvetica", sans-serif; font-weight: 700; text-transform: uppercase; font-size: 14px; text-align: left; }
      .urgency-slider { 
        & { position: relative; background: $color1opacity30; margin: 0 -30px; padding: 115px 9% 80px; border-radius: 3px; }
        h3 { position: absolute; top: 0; color: #858585; font-weight: 400; font-size: 18px; left: 7%; }

        .slider-labels {
          & { width: 125%; font-weight: 300; font-size: 16px; color: #858585; margin: 0 auto; }
          .label {
            & { width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
        }
      
      }

      .slider-points {
        & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
        .score { position: absolute; z-index: 100000; margin-left: -28px; margin-top: -10px; text-align: center; font-size: 18px; font-weight: bold; }
        .top-labels { position: absolute; width: 1px; height: 33px; top: -46px; margin-left: -2px; background: #1F2A44; }
        .top-points { position: absolute; top: -70px; margin-left: -6px; font-weight: bold; font-size: 18px; color: #5E5E5E; }
        .point {
          & { cursor: pointer; width: 13px; height: 13px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: $color1; position: absolute; z-index: 9; }
        }
      }
      

      .custom-slider.MuiSlider-root { z-index: 1000; color: $color1opacity60; }

      .custom-slider .MuiSlider-rail { opacity: 0; }
      .custom-slider .MuiSlider-track { border: 5px solid $color1opacity60; }

      .custom-slider .MuiSlider-thumb { background: $color1; }
      .custom-slider .MuiSlider-thumb:before { background: $color1; border: 4px solid $color1; width: 36px; height: 36px; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      
      .MuiLinearProgress-root.custom-bar { background-color: #D8D8D8; }
      .custom-bar .MuiLinearProgress-bar { background-color: $color1; }
    }

    .hurdles-enablers {
      & { max-width: calc(880px - 10%); margin: 20px auto; }
      h2 { font-family: "helvetica", sans-serif; font-weight: 700; text-transform: uppercase; font-size: 18px; text-align: left; }
      .hurdles { background: #fbece9;  padding: 10px 4% 1px 2%; font-family: "helvetica", sans-serif; font-size: 14px; line-height: 1.5em; color: #4A4A4A; text-align: left; border-radius: 3px; }
      .enablers { background: #f1f8f1;  padding: 10px 4% 1px 2%; font-family: "helvetica", sans-serif; font-size: 14px; line-height: 1.5em; color: #4A4A4A; text-align: left; border-radius: 3px; }
      li { padding-bottom: 16px; }
    }

  }

  .testimonal-container {
    & { max-width: calc(880px - 4%); margin: 50px auto 0; padding: 75px 2% 30px; font-family: $primaryfont; font-size: 24px; line-height: 36px; color: #1F2A44; display: flex; }
    .testimonial { 
      & { position: relative; color: $color7; font-family: $primaryfont; font-size: 24px; line-height: 45px; text-align: center; }
      .avatar {
        img { border-radius: 50%; width: 150px; margin-top: 50px; margin-bottom: -20px; }
      }
      .quotation-mark { 
        & { display: inline-block; font-size: 84px; color: #E74B16; margin-right: 15px; position: relative; }
        .quotation-block { position: absolute; right: 15px; top: -15px; }
      }
      .bottom { transform: scale(-1, -1); margin-left: 15px; }
      .designation { 
        & { color: #5CA9B4; font-weight: 700; padding-top: 32px; line-height: 1.1em; font-size: 32px; }
        span { font-weight: 600; font-size: 18px; color: $color7; }
      }
    }
  }

  .schedule-container {
    & { max-width: calc(1110px - 10%); width: 90%; background: $color7; margin: 20px auto; padding: 45px 5% 40px; font-family: $primaryfont, sans-serif; font-size: 24px; line-height: 36px; color: #fff; text-align: center; border-radius: 30px; }
    .cta-container { 
      & { position: relative; }
      .MuiSvgIcon-root { position: absolute; left: 50%; margin-left: -150px; margin-top: 14px; } 
    }
    .cta-btn {
      & { width: 220px; background: $color4; margin: 50px auto 0; color: #fff; padding: 2px 0; border-radius: 25px; }
      &:hover { background: $color6; transition: 0.3s; color: #1f2a44; }
      a { display: block; text-decoration: none; color: #fff; }
      button { color: #fff; font-family: $primaryfont, sans-serif; font-size: 21px; font-weight: 600; border-radius: 3px; }
    }
  }

  .buy-container {
    & { width: 100%; background: #fff; margin: 20px auto; height: 690px; font-family: $primaryfont, sans-serif; font-size: 24px; line-height: 36px; color: #fff; text-align: center; display: flex; }
    .buy-left { flex: 0 1 50%; background: url('./Assets/Images/buy-book.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; height: 690px; }
    .buy-right { 
      & { flex: 0 1 42%; color: $color3; padding: 20px 4%; display: flex; align-items: flex-start; justify-content: center; flex-direction: column; text-align: left; font-size: 24px; }
      h3 { font-size: 24px; font-weight: 700; text-transform: uppercase; margin-bottom: 10px; }
      span { color: $color4; font-weight: 700; margin-bottom: 10px; }
      .shop { display: block; float: left; position: relative; box-shadow: 2px 2px 5px 2px rgba(39, 40, 42, 0.25); margin-right: 10px; margin-bottom: 15px; border-radius: 5px; padding: 5px; }
      .shop-size-1 { width: 162px; height: 55px; }
      .shop-size-2 { width: 204px; height: 55px; }
      .amazon { position: absolute; top: 16px; left: 28px; }
      .audible { position: absolute; top: 9px; left: 24px; }
      .lifeway { position: absolute; top: 20px; left: 28px; }
      .barnesnoble { position: absolute; top: 19px; left: 5%; width: 90%; }
      .christianbooks { position: absolute; top: 16px; left: 5%; width: 97%; }
      .petergreer { position: absolute; top: 22px; left: 14%; width: 72%; }
      
      .cta-container { 
        & { position: relative; }
        .MuiSvgIcon-root { position: absolute; left: 50%; margin-left: -150px; margin-top: 14px; }

        .cta-btn {
          & { width: 220px; background: $color4; margin: 50px auto 0; color: #fff; padding: 2px 0; border-radius: 5px; text-align: center; }
          &:hover { background: $color6; transition: 0.3s; color: #1f2a44; }
          a { display: block; text-decoration: none; color: #fff; }
          button { color: #fff; font-family: $primaryfont, sans-serif; font-size: 21px; font-weight: 700; border-radius: 3px; }
        }
      }
    }

    
    
  }

}

.btn {
  & {
    
  }
}

// footer {
//   min-height: 100px;
// }

// footer { min-height: 100px; }



@media only screen and (max-width: 1350px) {

  header {
    .logo { margin-left: -5px; }
  }

  main .assessment .steps .slider-none-adjust { height: 33px; }

}

@media only screen and (max-width: 1250px) {

  header {
    .logo { margin-left: -5px; }
  }
  
  .custom-slider.MuiSlider-root { padding: 12px 0; }
  

}

@media only screen and (max-width: 1110px) {

  main .dashboard .urgency { overflow: hidden; }

}

@media only screen and (max-width: 830px) {

  // header {
  //   & { flex-direction: column; height: auto; }
  //   .logo { position: relative; margin-bottom: 20px; }
  // }
  
  main {
    .start {
      & { flex-direction: column; height: auto; width: 95%; }
      .video { width: 100%; height: 45vh; }
      .intro { width: 90%; padding: 20px 5% 80px; height: auto; }
    }
  }

  main .start .video video { width: 120%; margin: 0 -10%; }
  main .start .video .thumbnail { width: 100%; height: auto; }

  main .dashboard table { font-size: 15px; }
  main .dashboard .most-answered { width: 90%; }
  main .dashboard .urgency { width: 90%; }
  main .dashboard .hurdles-enablers { width: 90%; }
  

}

@media only screen and (max-width: 650px) {

  header .assessment-title { font-size: 30px; }
  main .assessment .steps { width: 90%; padding: 0 5%; }
  main .start .intro h1 { text-align: center; }
  main .start .intro h2 { text-align: center; font-size: 18px; }
  main .start .intro h1 br { content: ""; }
  main .start .intro h1 br:after { content: " "; }

}

@media only screen and (max-width: 550px) {

  main .start { margin-top: 15px; }
  main .start .video { border-top-left-radius: 10px; border-top-right-radius: 10px; border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
  main .start .intro h1 { font-size: 22px; }
  main .start .intro .completion img { margin: 0 4px 0 0;}
  main .assessment { margin-top: 22px; }
  main .assessment .steps .question { min-height: 180px; font-size: 21px; }
  main .assessment .steps .question br { content: ""; }
  main .assessment .steps .question br:after { content: " "; }
  main .assessment .steps .progress-bar { flex-wrap: wrap; }
  main .assessment .steps .progress-bar .bar-section .bar { height: 6px; margin: 0 5px 0; }
  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 12px; }
  main .assessment .steps .slider-container .slider-labels .label { width: 14%; }
  main .assessment .steps .progress-indicator { margin: 30px auto 30px; font-size: 18px; }
  main .assessment .steps .slider-container .slider-labels { font-size: 12px; }
  main .assessment .steps .step-buttons button span { display: none; }
  main .assessment .steps .step-buttons { padding-bottom: 20px; }
  main .assessment-additional { margin-top: 22px; }
  main .assessment-additional .steps .textarea-field { width: 100%; margin-left: -10px; }
  main .assessment-additional .steps .progress-indicator { font-size: 18px; }
  main .assessment-additional .steps .question { font-size: 21px; }
  main .assessment-additional .steps .question p br { content: ""; }
  main .assessment-additional .steps .question p br:after { content: " "; }
  main .assessment-additional .steps .slider-container .slider-labels { font-size: 12px; }
  main .assessment-additional .steps .step-buttons button span { display: none; }
  main .assessment-additional .steps .step-buttons { padding-bottom: 20px; }
  main .assessment-additional .steps .MuiInput-root { font-size: 18px; }

  main .details { width: 95%; margin-top: 22px; }
  main .details h1 { font-size: 32px; }
  main .details p { padding: 0 5%; font-size: 18px; }
  main .details p br { content: ""; }
  main .details p br:after { content: " "; }
  main .details .form { width: 90%; }
  main .details .form .form-detail { flex: 0 1 100%; }
  main .details .form .form-detail p { font-size: 14px; }
  main .details .form .form-detail .MuiInput-root { font-size: 18px !important; }
  main .details .form .form-detail .form-details-text { padding-right: 0; max-width: 94%; border-radius: 3px; }
  main .details .cta-btn button { width: 80vw; font-size: 18px; }

  main .results { width: 95%; margin-top: 22px; }
  main .results .selection-form { width: 70%; flex-wrap: wrap; flex-direction: column-reverse; }
  main .results .selection-form .form-detail { width: 100%; }
  main .results .selection-form .form-text { width: 100%; margin-bottom: 10px; text-align: center; }
  main .results h1 { font-size: 24px; width: 90%; }
  main .results h1 br { content: ""; }
  main .results h1 br::after { content: " "; }
  main .results .result-text { margin: 20px 5%; font-size: 18px; padding-bottom: 50px; }
  main .results .result-sections { margin: 50px 5%; }
  main .results .result-sections .score-section { flex: 0 1 100%; }
  main .results .progress-bar { width: 95%; }
  main .results .si_pro_header h4 { font-size: 13px; }
  main .results .si_pro_header h4.nimp:before { bottom: 55px; }
  main .results .si_pro_header h4.mexpe:before { bottom: 55px; }
  main .results .si_ass_progress .msi_score { font-size: 26px; }
  main .results .members-answered { width: 95%; font-size: 16px; }
  main .results .members-answered .answered-detail { font-size: 14px; padding: 10px 15px; width: calc(100% - 30px); }
  main .buy-container { flex-wrap: wrap; height: auto; }
  main .buy-container .buy-left { flex: 0 1 100%; }
  main .buy-container .buy-right { flex: 0 1 100%; align-items: center; }
  main .buy-container .buy-right h3 { font-size: 21px; }
  main .buy-container .buy-right p { text-align: center; font-size: 18px; }
  main .buy-container .buy-right .shop { float: none; margin: 0 auto; margin-bottom: 10px; }
  main .buy-container .buy-right .shop-size-1 { width: 204px; }
  main .buy-container .buy-right .amazon { left: 50px; }
  main .buy-container .buy-right .audible { left: 50px; }
  main .buy-container .buy-right .lifeway { left: 53px; }

  main .invite-container { width: 85%; font-size: 18px; }

  main .schedule-container { width: 85%; font-size: 18px; }

  main .testimonal-container { flex-wrap: wrap; padding-top: 12px; }
  main .testimonal-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }
  main .testimonal-container .testimonial .quotation-mark { font-size: 46px; }
  main .testimonal-container .testimonial .designation { font-size: 26px; }
  main .testimonal-container .testimonial .designation span { padding-top: 6px; }

  main .assessment-additional .steps .textarea { min-height: 120px; }
  main .assessment-additional .steps .textarea-field { margin-bottom: -77px; }

  main .invite { width: 95%; margin-top: 22px; }
  main .invite h1 { font-size: 32px; }
  main .invite p { margin: 25px 3% 35px; font-size: 18px; }
  main .invite p br { content: ""; }
  main .invite p br:after { content: " "; }
  main .invite .form { width: 72%; }
  main .invite .form .form-detail .MuiInput-root { font-size: 18px; }
  main .invite .form .form-detail { flex: 0 1 100%; }
  main .invite .form .form-detail .invite-field { max-width: 95%; border-radius: 3px; }
  main .invite .form .form-detail .error-text { bottom: auto; }
  main .invite .form .invite-number { left: -27px; top: 25px; }
  main .invite .form .delete-btn { right: auto; left: -34px; top: 65px; }
  main .invite .invite-another-person .invite-another { top: 0px; left: -11vw; padding: 11px 0; }
  main .invite .cta-btn { margin-top: -5px; margin-right: 10vw; width: 55vw; padding: 8px 0 8px; font-size: 18px; }
  
  main .thank-you { width: 95%; margin-top: 22px; }
  main .thank-you h1 { font-size: 32px; }
  main .thank-you p { width: 94%; margin: 25px 3% 35px; font-size: 18px; }
  main .testimonal-container .testimonial { font-size: 16px; width: 90%; margin: 20px auto 0; text-align: center; }

  main .dashboard { width: 95%; }
  main .dashboard h1 { font-size: 32px; }
  main .dashboard .result-text { margin: 20px 5%; font-size: 18px; }
  main .dashboard .result-sections { margin: 50px 5%; padding-bottom: 50px; }
  main .dashboard .result-sections .score-section { flex: 0 1 100%; }
  main .dashboard .progress-bar { width: 95%; }
  main .dashboard .si_pro_header h4 { font-size: 13px; }
  main .dashboard .si_pro_header h4.nimp:before { bottom: 55px; }
  main .dashboard .si_pro_header h4.mexpe:before { bottom: 55px; }
  main .dashboard .si_ass_progress .msi_score { font-size: 26px; }
  main .dashboard table { font-size: 13px; }
  main .dashboard table .footer .church-average { font-size: 13px; }
  main .dashboard table .header .center-cell { font-size: 0; }
  main .dashboard .urgency .slider-points .top-points { font-size: 13px; }
  main .dashboard .urgency .urgency-slider .slider-labels { font-size: 13px; }
  main .dashboard .most-answered { font-size: 16px; }
  main .dashboard .most-answered .most-answered-detail { font-size: 14px; }
  main .dashboard .alignment { width: 90%; }
  main .dashboard .alignment .tab-buttons button { font-size: 12px; min-width: 55px; padding: 10px 5px; }
  main .dashboard .alignment .content .align-details { font-size: 16px; }
  main .dashboard .alignment .content .align-details .answered-detail p { flex: 0 1 92%; }
  main .dashboard .alignment .content .align-details .answered-detail .more-toggle { flex: 0 1 8%; }

}

@media only screen and (max-width: 550px) {

  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 14px; margin-bottom: 31px; }  
  main .start .intro .completion { flex-wrap: wrap; margin: 48px auto 10px; width: 100%; justify-content: center;  }
  main .start .intro .completion .cta { width: 90%; margin: auto 15% 30px; }

}

@media only screen and (max-width: 450px) {

  // main .dashboard table { font-size: 11px; }
  // main .dashboard table .footer .church-average { font-size: 11px; }

}

@media only screen and (min-width: 550px) {

  main .assessment-additional .steps .slider-container .slider-labels .label p br { content: ""; }
  main .assessment-additional .steps .slider-container .slider-labels .label p br:after { content: " "; }
  
}

// Tabs CSS


// .tabs {
//   padding: 15px 0px;
//   text-align: center;
//   color: white;
//   width: 100%;
  
//   cursor: pointer;

//   box-sizing: content-box;
//   position: relative;
//   outline: none;
// }
// .active-tabs {
//   color: #000;
//   background: $color1;
//   border-bottom: 1px solid transparent;
//   &::before {
//     content: "";
//     display: block;
//     position: absolute;
//     top: -5px;
//     left: 50%;
//     transform: translateX(-50%);
//     width: calc(100%);
//     height: 0px;
//     background: rgb(88, 147, 241);
//   }
// }
// button {
//   border: none;
// }

